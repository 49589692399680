import React from "react";
// import { Link } from "react-router-dom";
import CardList from "../components/Card";

const Products = () => {
  return (
    <>
      <section className='products'>
        <h1>Explore Our Tooling Solutions</h1>
        <h5>
          <a
            href='../_assets/CNCToolingSolutionsLineCard.pdf'
            className='link'
            target='_blank'
          >
            Click Here to View Our Line Card
          </a>
        </h5>
        <div className='products__list'>
          <CardList />
        </div>
      </section>
    </>
  );
};

export default Products;
