import React from "react";

const ThankYou = () => {
  return (
    <section className='thankyou'>
      <h1>Thank You!</h1>
      <p>
        Thank you for reaching out to CNC Tooling Solutions. We will be in touch
        very soon.
      </p>
    </section>
  );
};

export default ThankYou;
